<template>
  <div class="sandbox">
    <div class="left">
      <a-card
        v-for="(item, index) in docModuleList"
        :key="index"
        :title="item.module"
        style="width: 100%; margin-top: 20px"
      >
        <p
          class="ff-list"
          :class="{ active: e.summary == leftActive }"
          v-for="(e, i) in item.docItems"
          :key="i"
          @click="selectButton(e, i)"
        >
          {{ e.summary }} {{ e.version }}
        </p>
      </a-card>
    </div>
    <div class="right">
      <div class="ff-content" v-if="docItems.summary == '自行实现签名'">
        <h2>
          <a-icon type="bars" style="margin-right: 10px" /><span>{{
            docItems.summary
          }}</span>
        </h2>
        <div class="xian"></div>
        <div class="ff-block">
          <div class="xian-title"><span>自行实现签名</span></div>
          <p>如果未使用开放平台SDK，需要自行实现签名过程。</p>
        </div>
        <div class="ff-block">
          <div class="xian-title"><span>如何签名</span></div>
          <p>1.筛选并排序</p>
          <p>获取所有请求参数，不包括字节类型参数，如文件、字节流，剔除sign字段，剔除值为空的参数，并按照第一个字符的键值ASCII码递增排序（字母升序排序），如果遇到相同字符则按照第二个字符的键值ASCII码递增排序，以此类推。</p>
          <p>2.拼接</p>
          <p>将排序后的参数与其对应值，组合成“参数=参数值”的格式，并且把这些参数用&字符连接起来，此时生成的字符串为待签名字符串。</p>
          <p>例如下面的请求示例，参数值都是示例，开发者参考格式即可：</p>
          <pre>
            REQUEST URL: http://open-test.yourdomain.com/api
            REQUEST METHOD: POST
            CONTENT:
            app_id=2014072300007148
            method=alipay.mobile.public.menu.add
            charset=GBK
            sign_type=RSA2
            timestamp=2014-07-24 03:07:50
            biz_content={"button":[{"actionParam":"ZFB_HFCZ","actionType":"out","name":"话费充值"},
              {"name":"查询","subButton":[{"actionParam":"ZFB_YECX","actionType":"out","name":"余额查询"},
              {"actionParam":"ZFB_LLCX","actionType":"out","name":"流量查询"},
              {"actionParam":"ZFB_HFCX","actionType":"out","name":"话费查询"}]},
              {"actionParam":"http://m.alipay.com","actionType":"link","name":"最新优惠"}]}
            sign=e9zEAe4TTQ4LPLQvETPoLGXTiURcxiAKfMVQ6Hrrsx2hmyIEGvSfAQzbLxHrhyZ48wOJXTsD4FPnt+
            YGdK57+fP1BCbf9rIVycfjhYCqlFhbTu9pFnZgT55W+xbAFb9y7vL0MyAxwXUXvZtQVqEwW7pURtKilbcBTEW7TAxzgro=version=1.0
          </pre>
          <p>则待签名字符串为：</p>
          <pre>
            app_id=2014072300007148&biz_content={"button":[{"actionParam":"ZFB_HFCZ","actionType":"out","name":"话费充值"},
            {"name":"查询","subButton":[{"actionParam":"ZFB_YECX","actionType":"out","name":"余额查询"},
            {"actionParam":"ZFB_LLCX","actionType":"out","name":"流量查询"},
            {"actionParam":"ZFB_HFCX","actionType":"out","name":"话费查询"}]},
            {"actionParam":"http://m.alipay.com","actionType":"link","name":"最新优惠"}]}&
            charset=GBK&method=alipay.mobile.public.menu.add&sign_type=RSA2&timestamp=2014-07-24 03:07:50&version=1.0
          </pre>
          <p>3.调用签名函数</p>
          <p>使用各自语言对应的SHA256WithRSA(对应sign_type为RSA2)或SHA1WithRSA(对应sign_type为RSA)签名函数利用商户私钥对待签名字符串进行签名，并进行Base64编码。</p>
          <p>4.把生成的签名赋值给sign参数，拼接到请求参数中。</p>
          <pre>
            /**
            @param content 加签内容
            @param privateKey 加签私钥
            @param charset 加签字符集
            @param charset 签名方法
            **/
            String AlipaySignature.rsaSign(String content, String privateKey, String charset,String signType)            
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "参数",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "值",
    className: "column-money",
    dataIndex: "money",
    scopedSlots: { customRender: "value" },
  },
];

const columnsIP = [
  {
    title: "环境",
    dataIndex: "title",
  },
  {
    title: "请求地址",
    dataIndex: "ip",
  },
];

const columnsParams = [
  {
    title: "参数",
    dataIndex: "name",
  },
  {
    title: "类型",
    dataIndex: "type",
  },
  {
    title: "是否必填",
    dataIndex: "required",
  },
  {
    title: "最大长度",
    dataIndex: "maxLength",
  },
  {
    title: "描述",
    dataIndex: "description",
  },
  {
    title: "示例值",
    dataIndex: "x-example",
  },
];

const data = [
  {
    key: "1",
    name: "John Brown",
    money: "￥300,000.00",
    address: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    name: "Jim Green",
    money: "￥1,256,000.00",
    address: "London No. 1 Lake Park",
  },
];
import { getDocBaseInfoApi, docinfoOrderApi } from "@/serve/Interface"
import { createResponseCode } from "@/tool"
export default {
  data() {
    return {
      ipArr: [{ key: 1, title: "正式环境", ip: "https://osapi.ecmoho.com" }],
      publiceParams: [
        {
          key: 0,
          name: "app_id",
          type: "String",
          required: "是",
          maxLength: 32,
          description: "平台分配给开发者的应用ID",
          "x-example": "2014072300007148",
        },
        {
          key: 1,
          name: "method",
          type: "String",
          required: "是",
          maxLength: 128,
          description: "接口名称",
          "x-example": "alipay.trade.close",
        },
        {
          key: 2,
          name: "format",
          type: "String",
          required: "否",
          maxLength: 40,
          description: "仅支持JSON",
          "x-example": "json",
        },
        {
          key: 3,
          name: "charset",
          type: "String",
          required: "是",
          maxLength: 10,
          description: "请求使用的编码格式，如utf-8,gbk,gb2312等",
          "x-example": "utf-8",
        },
        {
          key: 4,
          name: "sign_type",
          type: "String",
          required: "是",
          maxLength: 10,
          description:
            "商户生成签名字符串所使用的签名算法类型，目前支持RSA2和RSA，推荐使用RSA2",
          "x-example": "RSA2",
        },
        {
          key: 5,
          name: "sign",
          type: "String",
          required: "是",
          maxLength: 32,
          description: "平台分配给开发者的应用ID",
          "x-example": "2014072300007148",
        },
        {
          key: 6,
          name: "timestamp",
          type: "String",
          required: "是",
          maxLength: 19,
          description: '发送请求的时间，格式"yyyy-MM-dd HH:mm:ss"',
          "x-example": "2014-07-24 03:07:50",
        },
        {
          key: 7,
          name: "version",
          type: "String",
          required: "是",
          maxLength: 3,
          description: "调用的接口版本，1.0",
          "x-example": "1.0",
        },
        {
          key: 8,
          name: "notify_url",
          type: "String",
          required: "否",
          maxLength: 256,
          description:
            "平台服务器主动通知商户服务器里指定的页面http/https路径。",
          "x-example": "http://open.xxx.com/atinterface/receive_notify.htm",
        },
        {
          key: 9,
          name: "app_auth_token",
          type: "String",
          required: "否",
          maxLength: 40,
          description: "平台分配给开发者的应用ID",
          "x-example": "2014072300007148",
        },
        {
          key: 11,
          name: "biz_content",
          type: "String",
          required: "是",
          maxLength: "-",
          description:
            "请求参数的集合，最大长度不限，除公共参数外所有请求参数都必须放在这个参数中传递，具体参照各产品快速接入文档",
          "x-example": "",
        },
      ],
      publiceResponseParams: [
        {
          key: 0,
          name: "code",
          type: "String",
          required: "是",
          maxLength: "-",
          description: "网关返回码,详见文档",
          "x-example": "40004",
        },
        {
          key: 1,
          name: "msg",
          type: "String",
          required: "是",
          maxLength: "-",
          description: "网关返回码,详见文档",
          "x-example": "40004",
        },
        {
          key: 2,
          name: "sub_code",
          type: "String",
          required: "否",
          maxLength: "-",
          description: "业务返回码，参见具体的API接口文档",
          "x-example": "ACQ.TRADE_HAS_SUCCESS",
        },
        {
          key: 3,
          name: "sub_msg",
          type: "String",
          required: "否",
          maxLength: "-",
          description: "业务返回码描述，参见具体的API接口文档",
          "x-example": "交易已被支付",
        },
      ],
      form: {
        select: "",
      },
      arrayObj: {
        leftSelect: [],
      },
      docModuleList: [{
        module: '签名专区',
        docItems: [
          {
            summary: '自行实现签名'
                      
          }
        ]
      }],
      docItems: {},
      data,
      columns,
      columnsIP,
      columnsParams,
      leftActive: "自行实现签名",
    };
  },
  mounted() {
    this.docItems = this.docModuleList[0].docItems[0]
  },
  methods: {
    selectButton(e, i) {
      this.docItems = e
      this.leftActive = e.summary
      window.scrollTo(0, 0)
    },
  },
};
</script>
<style lang="scss">
.sandbox {
  width: 1200px;
  height: calc(100% - 60px);
  margin: 0 auto;
  display: flex;
  .left {
    width: 240px;
    padding-top: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    .ff-list {
      cursor: pointer;
      &.active {
        color: #1890ff;
      }
    }
  }
  .right {
    width: calc(100% - 240px);
    border-left: 1px solid #eee;
    padding-top: 20px;
    padding-left: 20px;
    pre{
      width: 100%;
      word-wrap: break-word;
      font-size: 12px;
    }
    .xian {
      width: 100%;
      height: 1px;
      background: #eee;
      margin: 20px 0;
    }
    .xian-title {
      position: relative;
      font-size: 20px;
      margin: 20px 0;
      span {
        position: relative;
        z-index: 2;
        background: #fff;
        padding: 0 5px;
        margin: 0 20px;
      }
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #eee;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .ff-set {
    margin: 20px 0;
  }
}
</style>
